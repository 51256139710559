.carousel{
  overflow: hidden;
}

.carousel-inner {
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.carousel-item{
  display: inline-block;
  align-items: center;
  text-align: left;
  justify-content: center;
  background-color: #fdfdfe;
  color: rgba(0, 0, 0, 0.78);
  inline-size: 100%;
  overflow-wrap: break-word;
  white-space: break-spaces !important;
  font-size: 25px;
  line-height: normal;
}

.button-group{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.navigate-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  outline-color: transparent;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
}

.indicator-group{
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  outline-color: transparent;
  background-color: rgba(98, 97, 97, 0.1);
  height: 10px;
  width: 100px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  font-size: 25px;
}
.indicator-active{
  background-color: rgb(217, 40, 41) !important;
}