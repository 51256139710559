// Variables

//Colors
$primary-color-bg: #29599e;//#1559A3;
$primary-color-font :#fff;

$red : #CD123D;
$grey50: #e5e3e3;
$grey80: #797979;
$grey70: #a9a9a9;
$black: #000000;
$footer-color: #464646;
$footer-color-bg: #EAEAEA;
$purple-font:#846add;
$pink:#EAEAEA;

$blue-link: #2185D0;

//overlay Colors

$overlay-black: rgba(0,0,0,0.8);


