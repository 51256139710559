@media only screen and (max-width: 768px) and (min-width: 479.98px) {

  /*Home style*/

  .top-title-home {
    margin-top: -32vh !important;
    align-items: center !important;
    text-align: left;
    padding: 15px 200px;
    color: $primary-color-bg;
    font-size: 40px !important;
  }

  .top-title-home-red {
    font-size: 40px !important;
  }

  .hero-banner-container{
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
  #logo-title{
    font-size: 20px;
  }

  .middle-right-trapezoid-inner-image{
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .home-trapezoid {
    position: absolute;
    //top: -10vh;
    width: 600px;
    margin-left: 10vw;
    //height: 580px;
    //text-align: center;
    //vertical-align: middle;
    ////transform: perspective(50px) rotateX(1.5deg);
    //overflow: hidden;
    //left:25vw;
  }

  .hero-banner{
    height: 60vh !important;
  }

  .left-trapezoid {
    position: relative;
    width: 300px;
    height: 0;
    border-left-width: 0px; /* Gradually reduce the border-left width */
    border-right: 0px solid transparent;
    border-bottom: 410px solid rgba(41, 89, 158, 0.5); /* Adjust color as needed */
    transition: all 500ms ease; /* Apply transition to all properties */
    background: url('../images/backgrounds/Auditors_Side.jpg') no-repeat top right;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .left-trapezoid:hover {
    width: 300px;
    border-left-width: 0px; /* Gradually reduce the border-left width */
    border-bottom: 410px solid rgba(41, 89, 158, 0.5);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .left-trapezoid:hover .home-trapezoid-button{
    top: 10vh;
    margin-left: 0px;
  }

  .left-trapezoid:hover .home-trapezoid-text-title{
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .left-trapezoid:hover .home-trapezoid-text-Details{
    visibility: visible;
    margin: 0px 30px 1.25rem;
    width: 80%;
    height: 110px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .left-trapezoid:hover .home-trapezoid-text {
    //cursor: pointer;
    height: 55px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .right-trapezoid {
    position: relative;
    width: 300px;
    height: 0;
    border-left: 0px solid transparent;
    border-right-width: 0px;
    border-bottom: 410px solid rgba(41, 89, 158, 0.5); /* Adjust color as needed */
    transition: all 500ms ease; /* Apply transition to all properties */
    background: url('../images/backgrounds/Public_Side.jpg') no-repeat top left;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .right-trapezoid:hover {
    width: 300px;
    border-right-width: 0px; /* Gradually reduce the border-left width */
    border-bottom: 410px solid rgba(41, 89, 158, 0.5);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }


  .right-trapezoid:hover .home-trapezoid-text-title-R{
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .right-trapezoid:hover .home-trapezoid-text-Details{
    visibility: visible;
    margin: 0px 35px 1.25rem;
    width: 80%;
    height: 110px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .right-trapezoid:hover .home-trapezoid-text-Details-R{
    visibility: visible;
    margin: 0px 35px 1.25rem;
    width: 80%;
    height: 110px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .right-trapezoid:hover .home-trapezoid-text {
    //cursor: pointer;
    height: 55px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  .right-trapezoid:hover .home-trapezoid-text-R {
    //cursor: pointer;
    height: 55px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
  }

  //.left-trapezoid {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking For Auditors - 1.png') no-repeat top center;
  //  background-size:contain;
  //  height:560px;
  //  text-align: center;
  //}
  //
  //
  //.right-trapezoid:hover {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking for Accreditation.png') no-repeat top center;
  //  background-size:contain;
  //  height:560px;
  //}
  //
  //.left-trapezoid:hover {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking for Auditor.png') no-repeat top center ;
  //  background-size:contain;
  //  height:550px;
  //  text-align: center;
  //}
  //
  //.right-trapezoid {
  //  transition: 2ms background ease ;
  //  background: url('../images/backgrounds/Looking For Accreditation - 1.png') no-repeat top center;
  //  background-size:contain;
  //  height:560px;
  //}

  .home-trapezoid-text {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 95%;
    left: 5%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 55px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-title {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $red;
    font-weight: bold;
    width: 95%;
    left: 5%;
    text-transform: uppercase;
    //padding: 0 20% ;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-Details {
    position: relative;
    display: flex;
    visibility: visible;
    //transition: all 500ms ease;
    margin: 0px 30px 1.25rem;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 80%;
    justify-content: center;
    text-transform: unset;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 18px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 110px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-R {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 95%;
    left: 5%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 55px;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-title-R {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $red;
    font-weight: bold;
    width: 95%;
    left: 5%;
    text-transform: uppercase;
    //padding: 0 20% ;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-Details-R {
    position: relative;
    display: flex;
    visibility: visible;
    //transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 80%;
    margin: 0px 35px 1.25rem;
    justify-content: center;
    text-transform: unset;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 18px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    line-height: normal;
    height: 110px;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-button {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    top: 10vh;
    margin-left: 0px;
    width: 300px;
  }

  .home-trapezoid-button-R {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    top: 10vh;
    //margin-left: 17%;
    width: 300px;
  }

  //.home-trapezoid-text {
  //  position: relative;
  //  display: flex;
  //  top: 20vh;
  //  color: $primary-color-font;
  //  font-weight: bold;
  //  width: 70%;
  //  text-transform: uppercase;
  //  text-align: center;
  //  vertical-align: middle;
  //  overflow: hidden;
  //  font-size: 16px;
  //  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  //}

  .home-slider-bottom{
    //height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, rgb(0 0 0 / 63%), rgb(0 0 0 / 63%)), url('https://www.agcs.allianz.com/news-and-insights/expert-risk-articles/construction-claims-trends/_jcr_content/root/stage/stageimage.img.82.3360.jpeg/1666955393504/agcs-grd-autumn-winter-2022-construction-claims-trends-stage.jpeg') no-repeat top center;
    background-size: 100%;
    padding-bottom: 10px;
    margin-top: 300px;
  }

  .home-content-text{
    position: relative;
    top: -90px;
    margin-left: 0%;
    width:100%;
    height: 220px;
    z-index: 12;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .home-content-text-2{
    position: relative;
    top: -90px;
    margin-left: 0%;
    width:100%;
    height: 220px;
    z-index: 12;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .home-content-title{
    font-size: 30px;
    font-weight: bold;
    color: $primary-color-bg;
    //line-height: 0px !important;
    margin-bottom: 10px !important;
  }

  .home-content-subtitle{
    font-size: 30px;
    font-weight: normal;
    color: $primary-color-bg;
    margin-bottom: 0px !important;
    //line-height: 0px !important;
  }

  /* End Home Style*/

  /*Profile Style*/

  .inner-page-trapezoid{
    position: absolute;
    border-color: $grey70;
    border-width: 0.15rem;
    border-style: solid;
    left: 33%;
    top: -450px;
    width: 67%;
    height: 210px;
    text-align: center;
    vertical-align: middle;
    transform: perspective(30px) rotateY(-1.5deg);
    overflow: hidden;
  }

  .inner-page-title{
    position: absolute;
    top: -390px;
    //left: 32%;
    width:100%;
    z-index: 12;
    text-align: center;
    //padding-left: 20vw;
    color: $primary-color-bg;
    font-size: 3rem;
    font-weight: bold;
    line-height: normal;
  }

  .inner-page-title-section{
    display: block;
    justify-content: left;
    position: absolute;
    top: 170px;
    margin-left: 10%;
    padding-left: 3vw;
  }

  .inner-titlebar-username{
    //position: absolute;
    display: inline-flex;
    //top: 350px;
    //margin-left: 10%;
    z-index: 12;
    text-align: left;
    //padding-left: 3vw;
    color: $primary-color-bg;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: normal;
    width: 100%;
  }

  .inner-titlebar-role{
    //padding-left: 25px;
    color: $red;
    font-size: 1.39rem;
    font-weight: lighter;
  }


  .inner-titlebar-lastlogged{
    //position: absolute;
    display: inline-flex;
    //top: 550px;
    //margin-left: 10%;
    width: 100%;
    z-index: 12;
    text-align: left;
    //padding-left: 3vw;
    color: $footer-color;
    font-size: 1.2rem;
  }

  .inner-titlebar-certexp{
    //position: absolute;
    display: inline-flex;
    //top: 450px;
   // margin-left: 10%;
    z-index: 12;
    text-align: left;
    //padding-left: 3vw;
    color: $footer-color;
    font-size: 1.8rem;
  }

  .other_exp{
    height: 100px;
  }

  /*End Profile Style*/

  .button-flex{
    display: flex !important;
    justify-content: center;
    margin-top: 10px !important;
  }

  .send-message-auditor-details{
   // display: inline-flex;
    font-style:normal;
    font-size:1rem;
  }

  .element_with_width{
    margin-top: -0px !important;
  }

  .process-report-number-input {
    width: 150px !important;
  }

  .process-report-number-input-2 {
    width: 100px !important;
  }

}