@import "./app.scss";

@import "mobile","tablet" ,"notebook" ,"desktop", "widedisplays";

@font-face {
  font-family: Proxima-Nova;
  src: url('../fonts/Proxima Nova Font.otf');
}

//Main theme
html, body {
  height: 100%;
  font-family: Proxima-Nova;
}

td{
  vertical-align: top;
}

.about-us-sub{
  background-color: $grey50;
  padding: 15px 25px;
  justify-content: center;
  display: flex;
}

.about-us-sub-url{
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
}

.trainingPrograms{
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: 16px;
  word-wrap: break-word;
}

a{
  color: $primary-color-bg;
}

.trainingPrograms th {
  border: 3px solid grey !important;
  background-color: #E4E4E4;
  padding: 5px 10px 5px 10px;
  text-align: left;
}
.trainingPrograms  tr  td {
  border: 3px solid grey !important;
  padding: 5px 10px 5px 10px;
  font-weight: normal;
  text-align: left;
}

.ui.action.input:not([class*="left action"]) > input {
  border-top-right-radius: 0.285714rem;
  border-bottom-right-radius: 0.285714rem;
  border-right-color: rgba(34, 36, 38, 0.15);
}

.accrdian-title{
  padding-left: 25px !important;
}

.wrapped{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.gray{
  color:$footer-color ;
}

.item-align-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
#logo{
  transition: 0.4s;
}

#auditorSearch{
  transition: 0.4s;
}

#white-top-bar{
  transition: 0.4s;
}

#blue-menu{
  transition: 0.4s;
}

.topBar {
  padding-top: 10px;
  // To fix to the top
  position: fixed;
  z-index: 1000;
  background-color: white;
  transition: 0.4s;
}

.inner-page-image{
  margin-top: 150px;
  margin-bottom: -100px;
}

.blue {
  background-color: $primary-color-bg !important;
}
.color-foreground{
  color:$primary-color-bg !important;
}
.ui.table{
    border: 0px solid transparent !important;
}

th{
  border: 0px solid transparent !important;
}

td{
  border: 0px solid transparent !important;
}

.dark-container{
  background:$grey50 !important;
  padding: 1.5rem;
  width: 100% !important;
}

.textWrap{
  white-space: nowrap;
}

.background-transaparent{
  background-color: transparent !important;
}

 .button-flex > .background-transaparent:hover{
  background-color: $primary-color-bg !important;
  color: $primary-color-font !important;
}

.button-flex > .background-transaparent > a{
  color: $blue-link !important;
}


.button-flex > .background-transaparent:hover > a{
  color: $primary-color-font !important;
}

.button-flex > .background-transaparent-red:hover{
  background-color:$red !important;
  color: $primary-color-font !important;
}

/*a{
  color: inherit;
}*/

a:hover{
  color: inherit;
}

.background-colored{
  background-color: $grey50 !important;
}

.background-colored-blue{
  background-color: $primary-color-bg !important;
  color: $primary-color-font !important;
}

.send-message-to-auditor-border{
  border-style: solid;
  border-color: $primary-color-bg;
  justify-content: center;
}
 .send-message-button{
   justify-content: end !important;
   display: flex !important;
 }

.top-title {
  display: flex !important;
  align-items: center !important;
  text-align: center;
  padding: 15px 0px;
  color: $primary-color-bg;
  font-size: 35px !important;
  transition: 0.4s;
}

.login-label {
  color: $primary-color-bg;
  font-size: 16px !important;
  font-weight: normal;
  transition: 0.4s;
}

.title-bar {
  margin-top: 0px !important;
}

.title-box {
  position: absolute;
  border-color: $footer-color;
  border-width: 0.1rem;
  border-style: solid;
  top: 35vh;
  width: 60%;
  margin-left: 40%;
  height: 170px;
  text-align: center;
  vertical-align: middle;
  transform: perspective(20px) rotateY(-0.8deg);
}

.title-box-text {
  padding-top: 10vh;
  transform: perspective(20px) rotateY(0.8deg);
  color: $primary-color-bg;
}

.footer {
  position: relative !important;
  bottom: 0 !important;
  width: 100% !important;
  background-color: $footer-color !important;
}

.footer-first-bg {
  height: 150px !important;
  background-color: $primary-color-bg;
  color: $primary-color-font;
  margin-bottom: -10px;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.footer-first-bg-mobile {
  //height: 200px !important;
  background-color: $primary-color-bg;
  color: $primary-color-font;
  margin-bottom: -10px;
  padding-top: 20px !important;
  padding-bottom: 40px !important;
}

.footer-second-bg {
  height: 20px !important;
  color: $primary-color-font;
  margin-bottom: 6px;
}

.footer-second-bg-mobile {
  height: 50px !important;
  color: $primary-color-font;
  margin-bottom: 6px;
}

.footer-links {
  color: $primary-color-font;
  cursor: pointer;
  font-size: 18px;
}

.link-font{
  color: $primary-color-font;
}

.copy-right-note {
  font-size: 10px !important;
}

// Sample pages
code {
  background: $footer-color;
  color: $primary-color-font;
}

.columnBack {
  background: $footer-color;
}

//Home Page
.home-slider-bottom-text{
  color: $primary-color-font;
  text-wrap: none;
  font-size: 35px;
}

.hero-banner{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, rgb(251 251 251 / 5%), rgb(251 251 251 / 5%)), url('../images/backgrounds/Hero_Image.jpg') no-repeat top center;
  background-size:100%
}

.hero-banner-container{
  height:70vh;
  margin-bottom:250px
}

.profile-message{
  font-size: 1.4rem;
}

.exp-instructions{
  font-size: 15px;
  //font-style: italic;
}

.red-title{
  color: $red !important;
  font-size: 2.4rem !important;
  font-weight: bold !important;
}

.carousel-image-container{
  height: 500px;
  padding-top: 40px
}

.title{
  color: $primary-color-bg !important;
  background-color: $pink;
  font-size: 2rem !important;
  font-weight: bold;
}

.title > .dropdown.icon{
  position: absolute;
  color: $footer-color !important;
  //margin-left: 77% !important;
}

//Maintenance Page Styles
.maintenance-bg {
  width: 100vw;
  height: 100vh;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-black;
  z-index: 2;
  color: $primary-color-font;
  text-align: center;
}

.main-img {
  margin: auto;
  width: 250px;
  margin-top: 5vh;
}

.logo {
  width: 100px;
  margin-left: 10px;
  margin-top: 10px;
}

.textBlock {
  font-family: "Segoe UI";
  text-align: center;
  font-size: 4rem !important;
  text-transform: uppercase;
  margin-top: -10px;
}

.item-width {
  width: 15% !important;
}

.subtext {
  text-align: center;
  font-family: "Segoe UI Light";
  font-size: 25px;
  line-height: 1.2;
}

.time-cicle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  width: 12vw;
  height: 12vw;
  color: $primary-color-font;
  flex-direction: column;
  flex-wrap: wrap;
}

.time-value {
  font-size: 5vw;
  line-height: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.time-label {
  font-family: "Segoe UI Light";
  font-size: 1.5vw;
  line-height: 1;
  color: $purple-font;
  text-transform: uppercase;
}

//Validation Messages

.email-validation {
  color: $red;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  font-family: "Segoe UI";
  margin-top: 5px;
  margin-left: 5px;
}

.error-element{
  border-style: solid;
  border-color: $red;
  padding: 20px;
}

.home-trapezoid-text:hover {
  cursor: pointer;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
}

//aboutus page by Shane W
.about-us-title{
  font-size: 2rem;
  margin-top:28px;
  margin-left:12.5%;
  margin-right:12.5%;
  color:$overlay-black
  
}

.topbarimage{
  margin-top: 14px;
  width:100%;
  display:block;
  height: auto;
}

.about-us-text{
  margin-left: 12.5%;
  margin-right: 12.5%;
  line-height: 1.5;
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 40px;
  font-weight: bold;
}

//contact us page by Shane W

.contact-us-form-info {
  margin-top: -55px;
  padding: 20px;
  width: 100%;
  height: 110%;
  font-size:16px;
  font-weight: bold;
  background-color: $grey50;
  
}

.test {
  font-size:20px;
  text-align: center;
}

//FAQs page by Shane W WIP

.faq-table {
  border: 1px solid $grey80!important;
  border-spacing:0;
  text-align: left;
  

}

.faq-subtitle {
  background-color: #fff;
  font-size: 18px!important;
  font-weight: bold;
  color:$black!important;
  font-family: Proxima-Nova !important;
}

.faq-page {
  padding-left:10px!important;
  padding-right:10px!important;
}

/* Style the tooltip */
h3[title] {
  position: relative;
  cursor: help;
}

.Toastify__toast--success {
  background-color: $primary-color-bg !important; /* Set the background color to blue */
  color: #ffffff !important; /* Set the text color to white */
}

.ant-pagination{
  display: flex;
  justify-content: flex-end;
}

.dropdown-left-label{
  text-align: left;
}

.reg-thank{
  margin-top: -50px;
}

.ui.button{
  background-color: #ffffff;
}

.ui.button:hover{
  background-color: #efefef;
}

/* The container */
.checkbox-container {
  display: initial;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-color: red;
  border-style:solid;
  border-width: 0.2rem;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
  border-style:solid;
  border-width: 0.2rem;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//***//

/* The container */
.checkbox-container-gen {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container-gen input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-gen {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-color: rgb(72, 72, 72);
  border-style:solid;
  border-width: 0.1rem;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox-container-gen:hover input ~ .checkmark-gen {
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container-gen input:checked ~ .checkmark-gen {
  background-color: #2196F3;
  border-color: #2196F3;
  border-style:solid;
  border-width: 0.2rem;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-gen:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container-gen input:checked ~ .checkmark-gen:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container-gen .checkmark-gen:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.training-widget{
  background-color: $grey50;
  padding: 15px 25px;
  justify-content: center !important;
  display: flex !important;
  height: 70px;
}

.training-widget-details{
  border-style: solid;
  padding: 15px 25px;
  border-width: thin;
  display: flex !important;
  height: 150px;
  overflow: auto;
}

.training-button{
  justify-content: flex-end !important;
  display: flex !important;
}

@media only screen and (max-width: 767.98px) {
  .ui.table:not(.unstackable) > thead, .ui.table:not(.unstackable) > thead > tr, .ui.table:not(.unstackable) > tfoot, .ui.table:not(.unstackable) > tfoot > tr, .ui.table:not(.unstackable) > tbody, .ui.table:not(.unstackable) > tr, .ui.table:not(.unstackable) > tbody > tr, .ui.table:not(.unstackable) > tr > th:not(.rowspanned), .ui.table:not(.unstackable) > thead > tr > th:not(.rowspanned), .ui.table:not(.unstackable) > tbody > tr > th:not(.rowspanned), .ui.table:not(.unstackable) > tfoot > tr > th:not(.rowspanned), .ui.table:not(.unstackable) > tr > td:not(.rowspanned), .ui.table:not(.unstackable) > tbody > tr > td:not(.rowspanned), .ui.table:not(.unstackable) > tfoot > tr > td:not(.rowspanned) {
    display: revert !important;
    width: auto !important;
  }
}