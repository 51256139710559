@media only screen and (max-width: 480px) {

  /*p{
    font-size: 1rem;
  }*/

  /*Home style*/

  strong > a, p > a, a {
    word-wrap: break-word;
    color: $primary-color-bg;
  }

  .contact-us-form-info{
    margin-top: 10px !important;
    padding: 20px 10px !important;
    height: 96% !important;
  }

  .top-title-home {
    margin-top: -30vh !important;
    text-align: center;
    padding: 15px 15px;
    color: $primary-color-bg;
    font-size: 27px !important;
  }
  
  .ui-grid-stackable {
    height: 80px;
  }

  .home-content {
    margin-top: 500px !important;
}
// .left-trapezoid, .right-trapezoid {
//     margin-bottom: 300px;
// }

  .top-title-home-red {
    font-size: 27px !important;
  }

  .ui.visible.left.overlay.sidebar {
    width: 280px;
  }

  .carousel-item{
    text-align: center !important;
  }

  .hero-banner{
    background-size:152% !important;
  }

  .hero-banner-container{
    height:70vh;
    margin-bottom:80px !important;
    padding-top: 0px !important;
  }

  //.home-trapezoid {
  //  position: absolute;
  //  top: -5vh;
  //  width: 90%;
  //  height: 430px;
  //  text-align: center;
  //  vertical-align: middle;
  //  //transform: perspective(50px) rotateX(1.5deg);
  //  overflow: hidden;
  //  left: 5%;
  //}

  .home-trapezoid {
    position: absolute;
    //top: -10vh;
    width: 1vw;
    margin-left: 10vw;
    //height: 600px;
    //text-align: center;
    //vertical-align: middle;
    ////transform: perspective(50px) rotateX(1.5deg);
    //overflow: hidden;
    //left:25vw;
  }

  .hero-banner{
    height: 60vh !important;
  }

  .left-trapezoid {
    position: relative;
    width: 300px;
    height: 0;
    border-left-width: 0px; /* Gradually reduce the border-left width */
    border-right: 0px solid transparent;
    border-bottom: 410px solid rgba(41, 89, 158, 0.5); /* Adjust color as needed */
    transition: all 500ms ease; /* Apply transition to all properties */
    background: url('../images/backgrounds/Auditors_Side.jpg') no-repeat top right;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    margin-top: -10px;
  }

  .right-trapezoid {
    position: relative;
    width: 300px;
    height: 0;
    border-left: 0px solid transparent;
    border-right-width: 0px;
    border-bottom: 410px solid rgba(41, 89, 158, 0.5); /* Adjust color as needed */
    transition: all 500ms ease; /* Apply transition to all properties */
    background: url('../images/backgrounds/Public_Side.jpg') no-repeat top left;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    margin-top: 30px;
  }


  //.left-trapezoid {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking For Auditors - 1.png') no-repeat top center;
  //  background-size:contain;
  //  height:550px;
  //  text-align: center;
  //}
  //
  //
  //.right-trapezoid:hover {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking for Accreditation.png') no-repeat top center;
  //  background-size:contain;
  //  height:550px;
  //}
  //
  //.left-trapezoid:hover {
  //  transition: 2ms background ease;
  //  background: url('../images/backgrounds/Looking for Auditor.png') no-repeat top center ;
  //  background-size:contain;
  //  height:550px;
  //  text-align: center;
  //}
  //
  //.right-trapezoid {
  //  transition: 2ms background ease ;
  //  background: url('../images/backgrounds/Looking For Accreditation - 1.png') no-repeat top center;
  //  background-size:contain;
  //  height:550px;
  //}

  .home-trapezoid-text {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 91%;
    left: 5%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 55px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-title {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $red;
    font-weight: bold;
    width: 95%;
    left: 5%;
    text-transform: uppercase;
    //padding: 0 20% ;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-Details {
    position: relative;
    display: flex;
    visibility: visible;
    //transition: all 500ms ease;
    margin: 0px 30px 1.25rem;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 80%;
    justify-content: center;
    text-transform: unset;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 18px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 110px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-R {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 95%;
    left: 5%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    height: 55px;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-title-R {
    position: relative;
    display: flex;
    transition: all 500ms ease;
    top: 10vh;
    color: $red;
    font-weight: bold;
    width: 95%;
    left: 5%;
    text-transform: uppercase;
    //padding: 0 20% ;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 22px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height: normal;
    width: 300px;
    margin-left: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-text-Details-R {
    position: relative;
    display: flex;
    visibility: visible;
    //transition: all 500ms ease;
    top: 10vh;
    color: $primary-color-font;
    font-weight: bold;
    width: 80%;
    margin: 0px 35px 1.25rem;
    justify-content: center;
    text-transform: unset;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-size: 18px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg, 0 0 10px $primary-color-bg , 0 0 10px $primary-color-bg;
    line-height: normal;
    height: 110px;
    //-webkit-text-stroke: 1px $footer-color;
  }

  .home-trapezoid-button {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    top: 10vh;
    margin-left: 0px;
    width: 300px;
  }

  .home-trapezoid-button-R {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    top: 10vh;
    //margin-left: 17%;
    width: 300px;
  }

  #HomeText{
    margin-top: 550px;
  }

  //.home-trapezoid-text {
  //  position: relative;
  //  display: flex;
  //  top: 20vh;
  //  color: $primary-color-font;
  //  font-weight: bold;
  //  width: 75%;
  //  text-transform: uppercase;
  //  text-align: center;
  //  vertical-align: middle;
  //  overflow: hidden;
  //  font-size: 12px;
  //  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  //}

  .home-slider-bottom{
    //height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, rgb(0 0 0 / 63%), rgb(0 0 0 / 63%)), url('https://www.agcs.allianz.com/news-and-insights/expert-risk-articles/construction-claims-trends/_jcr_content/root/stage/stageimage.img.82.3360.jpeg/1666955393504/agcs-grd-autumn-winter-2022-construction-claims-trends-stage.jpeg') no-repeat top center;
    background-size: 100%;
    padding-bottom: 10px;
  }

  .home-content-text{
    position: relative;
    top: -90px;
    margin-left: 0%;
    width:100%;
    height: 220px;
    z-index: 12;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .home-content-text-2{
    position: relative;
    top: -90px;
    margin-left: 0%;
    width:100%;
    height: 220px;
    z-index: 12;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .home-content-title{
    font-size: 30px;
    font-weight: bold;
    color: $primary-color-bg;
    //line-height: 0px !important;
    margin-bottom: 8px !important;
  }

  .home-content-subtitle{
    font-size: 30px;
    font-weight: normal;
    color: $primary-color-bg;
    margin-bottom: 0px !important;
    //line-height: 0px !important;
  }

  .carousel-image-container{
    height: 350px !important;
    padding-top: 0px !important;
  }

  .home-slider-bottom-text{
    font-size: 26px !important;
    padding: 10px;
  }

  .indicator {
    width: 70px !important;
  }

 /* End Home Style*/

  /*Profile Style*/

  .inner-page-title{
    position: absolute;
    top: -120px;
    width:100%;
    z-index: 12;
    text-align: center;
    color: $primary-color-bg;
    font-size: 2.0rem;
    font-weight: bold;
    line-height: normal;
    word-wrap: break-word !important;
    /*justify-content: center;
    display: flex;*/
    //margin: 0px 20px !important;
  }

  .inner-page-image{
    margin-top: 0px !important;
    margin-bottom: -70px !important;
    z-index: -10;
    visibility: collapse;
  }


  .inner-page-title-section{
    display: block;
    justify-content: left;
    position: absolute;
    top: 100px;
    margin-left: 10%;
    padding-left: 3vw;
  }

  .inner-titlebar-username{
    //position: absolute;
    display: inline-flex;
    //top: 25vh;
    //margin-left: 5%;
    z-index: 12;
    text-align: center;
    color: $primary-color-bg;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: normal;
    width: 100%;
  }

  .inner-titlebar-role{
    color: $red;
    font-size: 1.2rem;
    font-weight: lighter;
  }

  .inner-titlebar-lastlogged{
   // position: absolute;
    display: inline-flex;
    //top: 33vh;
    //margin-left: 10%;
    z-index: 12;
    text-align: left;
    color: $footer-color;
    //padding-left: 3vw;
    font-size: 0.8rem;
  }

  .inner-titlebar-certexp{
    //position: absolute;
    display: inline-flex;
    //top: 35vh;
   // margin-left: 10%;
    z-index: 12;
    text-align: center;
    //padding-left: 3vw;
    color: $black;
    font-size: 1rem;
  }

  .other_exp{
    height: 100px;
  }

   /*End Profile Style*/

  .button-flex{
    display: flex !important;
    justify-content: center;
    margin-top: 10px !important;
  }

  .send-message-auditor-details{
    //display: inline-flex;
    font-style:normal;
    font-size:0.8rem;
  }

  .dropdown-left-label{
    text-align: left !important;
  }

  .reg-thank{
    margin-top: -10px !important;
  }

  .mobile-width .item{
    width: 100%;
  }

  .gray{
    color:$primary-color-font !important;
  }

  .ui.popup{
    max-width: 100% !important;
    overflow: auto;
  }

  .element_with_width{
    margin-top: 0px !important;
  }

  #thank-page{
    margin-top: 0px !important;
  }

  #cmbmyAudits{
    margin-right: 0px !important;
  }

  #rsaAudits{
    min-width: 10px !important ;
  }

  #rsaCourses{
    min-width: 10px !important ;
  }

  canvas{
    width: 304px !important;
    height: 304px !important;
  }

  .process-report-number-input {
    width: 100px !important;
  }

  .reg_links{
    //margin-left: 20px;
  }

  .process-report-number-input {
    width: 100px !important;
  }

  .process-report-number-input-2 {
    width: 60px !important;
  }

}